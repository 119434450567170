$(function() {

  function _toggleSubMenu(navItem) {
    const subMenu = $(navItem).children('.main-sub-menu');
    const subMenuOpen = $(subMenu).is(":visible")
    if (subMenuOpen) {
      $(navItem).removeClass('expanded');
    } else {
      $(navItem).addClass('expanded');
    }
  }

  function _openSubMenu(e, target) {
    if ($(e.target).is('svg')) return
    $(target).addClass('expanded');
  }

  function _closeSubmenu(e, target) {
    if ($(e.target).is('svg')) return
    $(target).removeClass('expanded');
  }

  // expand sub-nav on hover
  $('.main-menu__nav-item.parent')
    .on('mouseenter', function(e) {
      _openSubMenu(e, this);
    })
    .on('mouseleave', function(e) {
      _closeSubmenu(e, this)
    });

  // for mobile, expand sub-nav on chevron-click
  $('.main-menu__caret').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();
    const navItem = $(this).parents('.main-menu__nav-item');
    _toggleSubMenu(navItem);
  })

  // mobile main menu
  $('.menu-button').on('click', function() {
    $('.menu-button').toggleClass('open');
    $('.main-menu').toggleClass('open');
    $('.main-header__search').toggleClass('open');
    $('.search-bar__input').attr('placeholder', 'Search poweringcareers.org')
    $('body').toggleClass('overflow-hidden');
  })

  // search icon animation
  $('.search-buttons__button').on('click', function() {
    $('.search-buttons__button').toggleClass('open');

    if ($('.main-header__search').is(':visible')) {
      $('.main-header__search').slideUp(100);
    } else {
      $('.main-header__search').slideDown(100, () => {
        $('#menuSearchBar').trigger('focus');
      });
    }
  })
});
